import PropTypes from 'prop-types';
import { memo, useMemo, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { DelimitedArrayParam, useQueryParam } from 'use-query-params';
import ExpansionList from 'components/common/ExpansionList';
import SearchBar from 'components/common/SearchBar';
import {
  ACCOUNT_TO_ACCOUNT_INBOUND,
  TRANSACTION_TYPE_BILL_PAY,
  EMPTY_SEARCH,
  TRANSACTION_TYPE,
  TRANSACTION_TYPE_PAL,
  TRANSACTION_TYPE_PAL_ESCROW,
  TRANSACTION_TYPE_PAL_INTEREST,
  TRANSACTION_TYPE_PAL_PRINCIPAL,
  TRANSACTION_TYPE_PERSON_TO_PERSON,
  TRANSACTION_TYPE_A2A_IN,
  TRANSACTION_TYPE_A2A_OUT,
  TRANSACTION_TYPE_A2A_NEW,
  TRANSACTION_TYPE_A2A_XFER,
} from 'consts';
import { useEBillEnabledSelector } from 'hooks/selectors/useUserFspConfigSelectors';
import { creditPayOnSelector } from 'selectors/userFspConfig';
import { selector as pageSettingSelector } from 'selectors/pageSetting';
import { update as updatePageSettings } from 'model/pageSetting';
import { compose, withProps } from 'recompose';
import {
  pageConnect as withPageConnect,
  pageHandlers as withPageHandlers,
  withRefreshOperation,
} from 'util/page';
import PayerDetailPanel from '../PayerDetailPanel';

const daysField = 'sendOn';
const defaultPageSettings = { order: 'desc', orderBy: 'sendOn' };
const isExpandedPanel = (params = [], name = '') =>
  params.some((param) => param === name);

const Transactions = ({
  ExpandablePanelProps,
  actionConstant,
  countBack,
  columnMeta,
  defaultExpanded,
  fspId,
  name,
  handleChange,
  handleSearch,
  isBusy,
  isLoading,
  items,
  payerId,
  priorityIndex,
  createSearchFields,
  settingType,
  supportsDeleted,
  actionRefetchData,
  refetchData,
  ...props
}) => {
  const dispatch = useDispatch();
  const columnMetaMemo = useMemo(() => columnMeta, [columnMeta]);
  const eBillOn = useEBillEnabledSelector(fspId, payerId);
  const creditPayOn = useSelector((state) =>
    creditPayOnSelector(state, fspId, payerId)
  );
  const [expandedIds] = useQueryParam('ep', DelimitedArrayParam);
  const [showSearchBar, setShowSearchBar] = useState(true);
  const { searchBys } =
    useSelector(
      (state) => pageSettingSelector(state, payerId, settingType),
      shallowEqual
    ) || [];

  const showAutopayFilter = searchBys.some(
    ({ field, value }) =>
      field === TRANSACTION_TYPE &&
      ((eBillOn && value === TRANSACTION_TYPE_BILL_PAY) ||
        (creditPayOn && value === ACCOUNT_TO_ACCOUNT_INBOUND))
  );

  const showPayALoanFilter = searchBys.some(
    ({ field, value }) =>
      field === TRANSACTION_TYPE &&
      (value === TRANSACTION_TYPE_PAL ||
        value === TRANSACTION_TYPE_PAL_ESCROW ||
        value === TRANSACTION_TYPE_PAL_INTEREST ||
        value === TRANSACTION_TYPE_PAL_PRINCIPAL)
  );

  const showA2AFilter = searchBys.some(
    ({ field, value }) =>
      field === TRANSACTION_TYPE &&
      (value.toLowerCase() === TRANSACTION_TYPE_A2A_IN.toLowerCase() ||
        value.toLowerCase() === TRANSACTION_TYPE_A2A_OUT.toLowerCase() ||
        value.toLowerCase() === TRANSACTION_TYPE_A2A_NEW.toLowerCase() ||
        value.toLowerCase() === TRANSACTION_TYPE_A2A_XFER.toLowerCase())
  );

  const showP2PFilter = searchBys.some(
    ({ field, value }) =>
      field === TRANSACTION_TYPE && value === TRANSACTION_TYPE_PERSON_TO_PERSON
  );

  const searchFields = createSearchFields({
    fspId,
    payerId,
    showAutopayFilter,
    showPayALoanFilter,
    showP2PFilter,
    showA2AFilter,
  });

  useEffect(() => {
    if (actionRefetchData) {
      refetchData();
    }
  }, [actionRefetchData, refetchData]);

  useEffect(() => {
    const fields = [...searchBys];

    const index = fields.findIndex(({ field }) => field === 'autopayOffOn');

    if (index > -1 && !showAutopayFilter) {
      fields.splice(index, 1);
      dispatch(
        updatePageSettings({
          payerId,
          searchBys: fields,
          type: settingType,
        })
      );
    }
  }, [dispatch, payerId, searchBys, settingType, showAutopayFilter]);

  useEffect(() => {
    const fields = [...searchBys];

    const index = fields.findIndex(({ field }) => field === 'loanNumber');

    if (index > -1 && !showPayALoanFilter) {
      fields.splice(index, 1);
      dispatch(
        updatePageSettings({
          payerId,
          searchBys: fields,
          type: settingType,
        })
      );
    }
  }, [dispatch, payerId, searchBys, settingType, showPayALoanFilter]);

  useEffect(() => {
    if (isExpandedPanel(expandedIds, name) || defaultExpanded) {
      setShowSearchBar(true);
    } else {
      setShowSearchBar(false);
    }
  }, [defaultExpanded, expandedIds, name]);

  return (
    <PayerDetailPanel
      endAdornment={
        showSearchBar ? (
          <SearchBar
            action={actionConstant}
            countBack={countBack}
            daysField={daysField}
            deletedId={ExpandablePanelProps.id}
            handleSearch={handleSearch}
            hasData={items && items.length > 0}
            isBusy={isBusy}
            isLoading={isLoading}
            primaryParam={payerId}
            priorityIndex={priorityIndex}
            searchFields={searchFields}
            settingId={payerId}
            settingType={settingType}
            supportsDeleted={supportsDeleted}
            showSearchBar={showSearchBar}
          />
        ) : undefined
      }
      fspId={fspId}
      payerId={payerId}
      {...ExpandablePanelProps}
    >
      <ExpansionList
        actionConstant={actionConstant}
        columnMeta={columnMetaMemo}
        data={items}
        defaultExpanded={defaultExpanded}
        isLoading={isLoading}
        onChange={handleChange}
        name={name}
        {...props}
      />
    </PayerDetailPanel>
  );
};

Transactions.propTypes = {
  ExpandablePanelProps: PropTypes.shape({
    heightOffset: PropTypes.number.isRequired,
    icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    id: PropTypes.string.isRequired,
    scrollTopOffset: PropTypes.number.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }).isRequired,
  actionConstant: PropTypes.object,
  columnMeta: PropTypes.array.isRequired,
  countBack: PropTypes.bool.isRequired,
  defaultExpanded: PropTypes.bool,
  fspId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  actionRefetchData: PropTypes.bool,
  refetchData: PropTypes.func.isRequired,
  isBusy: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  payerId: PropTypes.number,
  priorityIndex: PropTypes.number,
  createSearchFields: PropTypes.func.isRequired,
  settingType: PropTypes.string.isRequired,
  supportsDeleted: PropTypes.bool,
  manualDeletedOnly: PropTypes.bool,
};

Transactions.defaultProps = {
  actionConstant: {},
  actionRefetchData: false,
  isBusy: false,
  defaultExpanded: false,
  payerId: 0,
  priorityIndex: undefined,
  supportsDeleted: false,
  manualDeletedOnly: false,
};

export default compose(
  withProps(({ fspId, payerId }) => ({
    defaultPageSettings,
    emptySubheading: EMPTY_SEARCH,
    loadParams: { fspId, payerId },
  })),
  withPageConnect,
  withPageHandlers,
  withRefreshOperation,
  memo
)(Transactions);
